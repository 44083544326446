import { adminClient } from "@api/admin/AdminClient";
import DateRangePickerComponent from "@components/admin/DateRangePickerComponent";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { AdminShippingProviderCostsGetResponse, AdminShippingProviderCostsResponse, ShippingProviderType } from "@reshopper/admin-client";
import { toDanishPriceFormat } from "@utils/formatting";
import { endOfDay, startOfMonth } from "date-fns";
import { useEffect, useState } from "react";

export default function ShippingProviderCostsTable() {
	const beginningOfMonth = startOfMonth(new Date());
	const endOfToday = endOfDay(new Date());
	const [fromUtc, setFromUtc] = useState<Date>(beginningOfMonth);
	const [toUtc, setToUtc] = useState<Date>(endOfToday);

	const [shippingProviderTypes, setShippingProviderTypes] = useState<undefined | ShippingProviderType[]>();
	const [shippingProviderCosts, setShippingProviderCosts] = useState<AdminShippingProviderCostsGetResponse>();

	useEffect(() => {
		const getShippingProvider = async () => {
			const result = await adminClient().adminGetEnumsFromShippingProviderType({
				excludedShippingProviderTypes: ["coolRunnerDaoPickup", "coolRunnerDaoHome"]
			});
			setShippingProviderTypes(result.shippingProviderTypes);
		}

		getTableData();
		getShippingProvider();
	}, [])

	const getTableData = async () => {
		const response = await adminClient().adminShippingProviderCostsGet({
			fromUtc,
			toUtc,
			excludedShippingProviderTypes: ["coolRunnerDaoPickup", "coolRunnerDaoHome"]
		});

		setShippingProviderCosts(response);
	}

	function onSubmit(fromDate: Date | null, toDate: Date | null) {
		if (!fromDate || !toDate) {
			return;
		}
		setFromUtc(fromDate);
		setToUtc(toDate);
		getTableData();
	}

	const renderShippingProviderCostForShippingProviderType = (shippingProviderType: ShippingProviderType, costs: AdminShippingProviderCostsResponse[]) => {
		const result = costs.find(e => e.shippingProviderType == shippingProviderType);

		if (result != null) {
			return <TableCell align="left">
				{toDanishPriceFormat(result.totalCost/100)} ({result.count ?? 0})
			</TableCell>;
		} else {
			return <TableCell align="left"></TableCell>;
		}
	}

	if (!shippingProviderCosts || !shippingProviderTypes) {
		return <></>
	}

	return <>
		<DateRangePickerComponent
			onSubmit={onSubmit}
			renderTimeOption={false}
			renderDateRanges={true}
			disableFutureDates={true}
			disablePastDates={false}
			submitOnLoad={false}
			variant="outlined"
			defaultFromDate={fromUtc}
			defaultToDate={toUtc}
		/>
		<Table>
			<TableHead>
				<TableRow>
					<TableCell />
					{shippingProviderTypes.map((provider, index) =>
						<TableCell align="left" key={index + provider}>{provider}</TableCell>
					)}
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<TableCell align="left">acceptedUtc and not canceled</TableCell>
					{shippingProviderTypes.map((provider) =>
						renderShippingProviderCostForShippingProviderType(provider, shippingProviderCosts.shippingProviderCosts)
					)}
				</TableRow>
				<TableRow>
					<TableCell align="left">acceptedUtc and canceled</TableCell>
					{shippingProviderTypes.map((provider) => (
						renderShippingProviderCostForShippingProviderType(provider, shippingProviderCosts.cancelledPackagesCosts)
					))}
				</TableRow>
				<TableRow>
					<TableCell align="left">Lost packages</TableCell>
					{shippingProviderTypes.map((provider) => (
						renderShippingProviderCostForShippingProviderType(provider, shippingProviderCosts.lostPackages)
					))}
				</TableRow>
			</TableBody>
		</Table>
	</>
}