import { withAdminMenuLayout } from "@components/admin/Decorators";
import PageTitle from "@components/admin/PageTitle";
import ShippingProviderCostsTable from "@components/admin/transactions/ShippingProviderCostsTable";

export default withAdminMenuLayout(function ShippingProviderCostsPageComponent() {

	return <>
		<PageTitle title="Shipping Provider Costs"/>
		<ShippingProviderCostsTable/>
	</>
});